<template>
  <section>
    <b-card>
      <b-card-header style="padding-top: 0px; padding-right: 0px">
        <div class="d-flex align-items-center">
          <b-card-title>
            <h4>
              <b style="color: cornflowerblue">Tambahkan Nama Peserta</b>
            </h4>
          </b-card-title>
        </div>
      </b-card-header>

      <b-form method="post" enctype="multipart/form-data"
        @submit.prevent="addData()">
        <b-row>
          <b-col md="6" xl="1" class="mb-1"></b-col>
            <b-col md="6" xl="8" class="mb-1">
              <v-select
                name="register-kampus"
                placeholder="Cari Nama Peserta"
                v-model="users_id"
                :value="id"
                :reduce="(nama) => nama.id"
                :options="listUser"
                label="nama"
              >
              </v-select>
            </b-col>

          <b-col md="6" xl="2" class="mb-1">
            <b-button
              variant="success"
              style="width: 100%"
              type="submit"
            >
              <!-- <feather-icon icon="PlusSquareIcon" style="color: white" /> -->
              <span class="align-middle"
                ><b style="color: white"> Daftarkan</b></span
              >
            </b-button>
          </b-col>
        </b-row>
      </b-form>

      <b-col cols="12">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
            disableSelectInfo: false, // disable the select info panel on top
            selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Action -->
            <span
              v-if="props.column.field === 'id'"
              class="btn-icon text-center"
            >
            {{ props.index + 1}}
                </span>
            <!-- Column: Action -->
            <span
              v-else-if="props.column.field === 'action'"
              class="btn-icon text-center"
            >
            
                  <b-button
                    variant="gradient-danger"
                    class="btn-icon"
                    @click="deleteData(props.row.id, props.row.plans_id, props.row.users_id)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
              
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10', '20', '50']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  :align="pagnation_posisition"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-card>
  </section>
</template>
<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
  BCardHeader,
  BTooltip,
  BCardTitle,
  BNav,
  BNavItem,
  BCol,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table/src";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";
export default {
  components: {
    VueGoodTable,
    BCard,
    ToastificationContent,
    BAvatar,
    Swal,
    BBadge,vSelect,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCardHeader,
    BNav,
    BNavItem,
    BTooltip,
    BCardTitle,
    BCol,
  },
  data() {
    return {
      id:"",
      selected: "right",
      pagnation_posisition: "right",
      pageLength: 10,
      columns: [
        {
          label: "ID",
          field: "id",
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: "Nama Jadwal",
          field: "nama_plan",
        },
        {
          label: "Nama Peserta",
          field: "nama_user",
        },

        {
          label: "Aksi",
          field: "action",
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      rows: [],
      searchTerm: "",
      listUser: [],
      users_id:"",
      plans_id: this.$route.params.id
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };
      return (status) => statusColor[status];
    },
  },
  async mounted() {
    this.checkAuth();
    await this.getData();
    await this.getUser();
  },
  methods: {
    checkAuth() {
      var sessionCheck = localStorage.getItem("Uid");
      var levelCheck = localStorage.getItem("Ulevel");
      if (sessionCheck == null || sessionCheck == "") {
                    this.$router.push({ name: "login" });
      }
      else if(levelCheck != 2){
            this.$router.push({ name: "dashboard" });
      }
    },
    async getData() {
      await this.$http
        // .get(process.env.VUE_APP_BACKEND_URL + "registerplans/view")
        .get(
        process.env.VUE_APP_BACKEND_URL +
          `registerplans/view/${this.$route.params.id}`
      )
        .then((res) => {
          this.rows = res.data.data;
        });
    },
    async addData() {
      const formData = new FormData();
      formData.append("users_id", this.users_id);
      formData.append("plans_id", this.plans_id);
      formData.append("status", "Pending");
      await this.$http
        .post(process.env.VUE_APP_BACKEND_URL + "registerplans/create", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          // console.log("mikki", res.data.success);
          if (res.data.success == false) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Notification",
                  icon: "xIcon",
                  text: "Data sudah Ada",
                  variant: "danger",
                },
              },
              {
                timeout: 5000,
              },
              {
                position: "top-right",
              }
            );
          } else {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "CheckCircleIcon",
                text: "Berhasil Ubah Data",
                variant: "success",
              },
            },
            {
              timeout: 5000,
            },
            {
              position: "top-right",
            }
          );
        }
          this.getData()
          // this.$router.push({ name: "list_plan" });
          // this.$router.go()
          // this.nama = "";
          // this.deskripsi = "";
          // this.catatan = "";
          // this.users_id = "";
          // this.id_event = "";
          // this.durasi = "";
          // this.jumlah_soal = "";
          // this.status = "";
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async deleteData(id, plans_id, users_id) {
      await Swal.fire({
        title: "Ingin Hapus Data?",
        text: "Data akan dihapus?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
      })
        .then((result) => {
          // console.log("test",id, plans_id, users_id)
          if (result.value) {
            this.$http
              .delete(process.env.VUE_APP_BACKEND_URL 
              + `registerplans/delete/${id}`
              + `/` + `${plans_id}`
              + `/` + `${users_id}`)
              .then((result) => {
                this.getData();
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Notification",
                      icon: "CheckCircleIcon",
                      text: "Hapus Data",
                      variant: "danger",
                    },
                  },
                  {
                    timeout: 5000,
                  },
                  {
                    position: "top-right",
                  }
                );
              });
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Notification",
                  icon: "CheckCircleIcon",
                  text: "Batal Hapus Data",
                  variant: "warning",
                },
              },
              {
                timeout: 5000,
              },
              {
                position: "top-right",
              }
            );
          }
        })
        .then((result) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    getUser() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "users/view")
        .then((res) => {
          this.listUser = res.data.data;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
